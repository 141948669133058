import { requestRemotePolyfill } from './polyfill';

const callback = () => {
  if (window._bt.isSsr) {
    import(/* webpackChunkName: "ssr" */ './ssr');
  } else {
    import(/* webpackChunkName: "csr" */ './csr');
  }
};

// Promise.finally is not available on iOS 11
requestRemotePolyfill().then(callback).catch(callback);
