export function requestRemotePolyfill() {
  return new Promise((resolve, reject) => {
    const id = 'btr_polyfillio';
    const callbackName = '_btrRender_io';

    window[callbackName] = () => {
      resolve();
    };

    const features = [
      'AbortController',
      'Array.from',
      'Array.isArray',
      'Array.prototype.entries',
      'Array.prototype.every',
      'Array.prototype.fill',
      'Array.prototype.filter',
      'Array.prototype.find',
      'Array.prototype.findIndex',
      'Array.prototype.flat',
      'Array.prototype.flatMap',
      'Array.prototype.forEach',
      'Array.prototype.includes',
      'Array.prototype.indexOf',
      'Array.prototype.keys',
      'Array.prototype.lastIndexOf',
      'Array.prototype.map',
      'Array.prototype.reduce',
      'Array.prototype.some',
      'Array.prototype.values',
      'ArrayBuffer',
      'Blob',
      'console',
      'CustomEvent',
      'DataView',
      'Date.now',
      'Date.prototype.toISOString',
      'document',
      'document.currentScript',
      'Element',
      'Event',
      'fetch',
      'Float32Array',
      'Function.prototype.bind',
      'IntersectionObserver',
      'IntersectionObserverEntry',
      'Intl',
      'Intl.DateTimeFormat.~timeZone.all',
      'Intl.DateTimeFormat.~timeZone.golden',
      'Intl.DateTimeFormat.prototype.formatToParts',
      'Intl.DateTimeFormat',
      'Intl.DisplayNames',
      'Intl.getCanonicalLocales',
      'Intl.ListFormat',
      'Intl.Locale',
      'Intl.NumberFormat',
      'Intl.PluralRules',
      'Intl.RelativeTimeFormat',
      'JSON',
      'localStorage',
      'Map',
      'Number.isNaN',
      'Object.assign',
      'Object.create',
      'Object.defineProperties',
      'Object.defineProperty',
      'Object.entries',
      'Object.freeze',
      'Object.fromEntries',
      'Object.getOwnPropertyDescriptors',
      'Object.getOwnPropertySymbols',
      'Object.isFrozen',
      'Object.seal',
      'Object.setPrototypeOf',
      'Object.values',
      'Promise',
      'Reflect',
      'Reflect.construct',
      'requestAnimationFrame',
      'requestIdleCallback',
      'ResizeObserver',
      'Set',
      'String.prototype.includes',
      'String.prototype.padEnd',
      'String.prototype.startsWith',
      'String.prototype.trim',
      'Symbol',
      'Symbol.for',
      'Symbol.iterator',
      'Symbol.prototype.description',
      'Symbol.toStringTag',
      'Uint16Array',
      'Uint8Array',
      'URL',
      'URLSearchParams',
      'WeakMap',
      'WeakSet',
      'XMLHttpRequest',
    ];

    const script = document.createElement('script');
    script.src = `https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=${features.join(
      '%2C'
    )}&flags=gated&callback=${callbackName}`;
    script.id = id;
    script.async = true;
    script.type = 'text/javascript';
    script.crossOrigin = 'anonymous';
    script.referrerPolicy = 'no-referrer';
    script.onerror = (err) => {
      reject(err);
    };
    document.head.appendChild(script);
  });
}
